export const Languages = {
  "en": "Eng",
  "ru": "Рус"
}

export const LinkApp = {
  MAC: "/Architeque.pkg",
  WIN: "/Architeque.msi"
}

export const KeyCodes = {
  ENTER: 13
}
