<template>
  <div class="account-container">
    <div class="catalog__bg"></div>
    
    <div class="wrapper">
      <b-row class="account__nav">
        <b-col md="3">
          <b-nav pills vertical class="account-menu">
           <b-nav-item              :to="{name: 'Profile'}"        exact-active-class="active" exact>{{ $t('account.profile') }}</b-nav-item>
           <b-nav-item class="mt-2" :to="{name: 'Subscriptions'}"  exact-active-class="active"      >{{ $t('account.subscriptions') }}</b-nav-item>
           <b-nav-item class="mt-2" :to="{name: 'Billing'}"        exact-active-class="active"      >{{ $t('account.billing-history') }}</b-nav-item>
           <b-nav-item class="mt-2" :to="{name: 'Logout'}"         exact-active-class="active"      >{{ $t('account.logout') }}</b-nav-item>
         </b-nav>
       </b-col>
       <b-col md="9">
        <b-card>
          <b-card-text>
            <router-view></router-view>    
          </b-card-text>
        </b-card>
       </b-col>
     </b-row>
   </div>
 </div>
</template>
<script>

  export default {
    methods: {
      logout(){
        this.$store.commit("logout");
        this.$router.push({name: 'Login'});
      }
    },
    mounted() {
      let stripeScript = document.createElement('script')
      stripeScript.setAttribute('src', 'https://js.stripe.com/v3/')
      document.head.appendChild(stripeScript)
    }
  }
</script>

<style lang="scss">

.account__nav {
  flex-direction: column;
  

  @extend .font-main;

  @media (min-width: $tablet-width) {
    flex-direction: row;
  }
}
.account-menu {

}
.account-container {
  max-width: 1200px;
  min-width: 1200px;
  min-height: 800px;
  margin: auto;
}
.card-body h3 {
  font-weight: 600 !important;
}
</style>