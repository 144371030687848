<template>
  <b-container class="mw-500">
    <div class="promo__bg"></div>
    <b-jumbotron id="login-container" class="login-container">
      <form>
        <div class="login_title">{{ $t("login.title") }}</div>
        <b-form-input
          v-bind:placeholder="$t('login.email')"
          v-model="email"
          autocomplete="username"
          class="login-input"
        ></b-form-input>
        <b-form-input
          autocomplete="current-password"
          type="password"
          class="mt-2 login-input"
          v-bind:placeholder="$t('login.password')"
          v-model="password"
        ></b-form-input>
        <b-alert :show="alert.state" :variant="alert.variant" class="mt-2">
          {{ alert.message }}
        </b-alert>
        <b-button
          class="login-button"
          id="login-button"
          block
          variant="primary mt-3"
          :disabled="loginInProcess"
          @click="login()"
        >
          <span class="text-white" v-if="!loginInProcess">{{ $t("login.login-btn") }}</span>
          <b-spinner v-if="loginInProcess" variant="light"></b-spinner>
        </b-button>
        <div class="mt-4 text-center">
          {{ $t("login.no-account") }}
          <router-link class="font-weight-bold" :to="{ name: 'Register' }">{{
            $t("login.register-link")
          }}</router-link>
        </div>
        <div class="mt-4 text-center fs-075">
          {{ $t("login.forgot-password") }}
          <router-link to="/reset-password">{{
            $t("login.click-here")
          }}</router-link>
        </div>
      </form>
    </b-jumbotron>
  </b-container>
</template>

<script>
import { KeyCodes } from "../const"
export default {
  data() {
    return {
      email: "",
      password: "",
      alert: {
        state: false,
        message: "",
        variant: "danger",
      },
      loginInProcess: false
    }
  },
  created() {
    document.title = this.$root.$i18n.messages[this.$root.$i18n.locale].titles["login"]
  },
  updated() {
    document.title = this.$root.$i18n.messages[this.$root.$i18n.locale].titles["login"]
  },
  mounted() {
    document.getElementById('login-container').addEventListener("keydown", this.onLoginEnterClick)

    if (this.$route.query && this.$route.query.message) {
      this.alert.message = this.$route.query.message
      this.alert.variant = "success"
      this.alert.state = true
    }
  },
  methods: {
    resetKeyListener: function() {
      document.removeEventListener("keydown", this.onLoginEnterClick)
    },
    login() {
      this.alert.state = false
      this.alert.message = ""
      this.loginInProcess = true
      let _this = this
      
      this.axios
        .post(
          process.env.VUE_APP_API_BASEURL + "/api/v1/auth/login", { email: this.email, password: this.password }
        )
        .then(function(response) {
          _this.loginInProcess = false
          if (response.data && response.data.token) {
            _this.$store.commit("login", response.data.token)
            _this.$router.push({ name: "Profile" })
          }
        })
        .catch(function(error) {
          _this.alert.state = true
          _this.alert.variant = "danger"
          _this.alert.message = error.response && error.response.data && error.response.data.cause
          _this.loginInProcess = false
        })
    },
    onLoginEnterClick(evt) {
      if (evt.keyCode === KeyCodes.ENTER) {
        this.login();
      }
    }
  }
}
</script>

<style lang="scss">
.mw-500 {
  @extend .font-main;
}
.login_title {
  font-size: 24px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 20px;
}
.promo__bg {
  position: absolute;
  display: block;
  top: 0;
  right: 0;

  z-index: -1;
  width: 100vw;
  height: 100vh;

  background-image: url("./../assets/Bg-main.png");

  background-repeat: no-repeat;
  background-position: top right;
  @media (max-width: $mobile-width-only) {
    display: none;
  }
}
.login-button {
  font-size: 1.2rem !important;
  height: 40px;
}
.login-container {
  margin-top: 50px;
  @media (max-width: $mobile-width-only) {
    margin-top: 0px !important;
  }
}
.login-input {
  margin-bottom: 15px !important;
  height: calc(2em + 0.75rem + 2px) !important;
  border: 1px solid #9ea4ab !important;
}
</style>
