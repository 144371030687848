<template>
  <main>
    <div class="promo__bg"></div>
    <h1 class="visually-hidden">{{ $t("business.main-title-charter") }}</h1>
    <div class="in-container">
      <h2 class="business__title highlighted">
        {{ $t("business.title-charter") }}
      </h2>
    </div>
    <section class="business__section">
      <div class="business__upper in-container">
        <img
          class="business__img"
          width="391px"
          height="442px"
          src="./../assets/promo_business.png"
          v-bind:alt="$t('business.alt-img.display')"
        />
        <div class="business__upper-features">
          <h3 class="business__title-section">
            {{ $t("business.title-section") }}
          </h3>
          <FeaturesList nameOfFeatures="business"></FeaturesList>
        </div>
      </div>
      <div class="business__lower in-container">
        <div style="width: 100%; aspect-ratio: 1.78; margin: auto;">
            <iframe src="https://www.youtube.com/embed/un819iJ2DDo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen class="embed-video"></iframe>
            <!--img width="391px" height="442px" src="./../assets/sample02.jpg" v-bind:alt="$t('development.alt-img.phones')"-->
        </div>
        <div id="archengine-embed-container-business"> 
          <iframe src="https://static.ar0.app/archweb.html?uiStyle=dark&allowScale=1&allowMoveCameraSideways=0&showCredit=0&allow-fullscreen=1&annotations-toggle=1&scene=b42a768220fa3555"  allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true" title="ARCHITEQUE 3D" frameborder="0" style="width: 100%; height: 100%;"></iframe>
        </div>
      </div>
      <div class="business__descriptions-bg">
        <DesctiptionsList
          nameOfDescroptions="business"
          :listOfDescriptions="descriptions"
        ></DesctiptionsList>
      </div>
    </section>
    <div class="business__subtitle in-container">
      <h2 class="business__title highlighted">
        {{ $t("business.for-clients") }}
      </h2>
    </div>
  </main>
</template>

<script>
import FeaturesList from "./FeaturesList.vue";
import DesctiptionsList from "./DesctiptionsList.vue";
export default {
  components: { FeaturesList, DesctiptionsList },
  data: function() {
    return {
      descriptions: ["instant-demo", "scale", "access", "upload", "interact"]
    };
  },
  created() {
    document.title = this.$root.$i18n.messages[this.$root.$i18n.locale].titles[
      "business"
    ];
  },
  updated() {
    document.title = this.$root.$i18n.messages[this.$root.$i18n.locale].titles[
      "business"
    ];
  }
};
</script>

<style lang="scss">
.business__title {
  text-align: center;
  margin-bottom: 0 !important;
  font-size: 36px !important;
  line-height: 44px !important;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: $tablet-width) {
    margin-bottom: 35px !important;
  }

  @extend .font-title;
}

#archengine-embed-container-business {
  width: 100%;
  margin: auto;
  aspect-ratio: 1.78;

  @media (max-width: $mobile-width-only) {
    margin-top: 30px;
  }
}

#archengine-embed-container-business iframe {
  background-color: #ededed;
  box-shadow: inset 0px 0px 180px rgba(0, 0, 0, 0.3);
}

.business__title-section {
  margin-bottom: 20px !important;

  @media (max-width: $mobile-width-only) {
    margin-top: 25px !important;
    margin-bottom: 20px !important;
  }

  @extend .title-section;
}

.business__upper-features {
  @media (min-width: $tablet-width) {
    padding-top: 30px;
  }
}

.business__img {
  width: auto;
  max-width: 100%;
  height: auto;
  padding-top: 50px;
}

.business__upper {
  display: grid;
  padding: 0 10% 10px;

  @media (min-width: $tablet-width) {
    padding-bottom: 70px;
    grid-gap: 7%;
    grid-template-columns: 47% 44%;
  }
}

.business__lower {
  display: grid;

  @media (min-width: $tablet-width) {
    padding-bottom: 70px;
    grid-gap: 2%;
    grid-template-columns: 49% 49%;
  }
  @media (max-width: $mobile-width-only) {
    display: block;
    margin-bottom: 20px;
  }
}

.descriptions__item--instant-demo::before {
  background-image: url("./../assets/instant-demo.png");
}

.descriptions__item--scale::before {
  background-image: url("./../assets/scale.png");
}

.descriptions__item--access::before {
  background-image: url("./../assets/access.png");
}

.descriptions__item--upload::before {
  background-image: url("./../assets/upload.png");
}

.descriptions__item--interact::before {
  background-image: url("./../assets/interact.png");
}

.business__subtitle {
  padding-top: 50px;
  padding-bottom: 100px;
  text-align: center;

  .business__title {
    display: inline;

    @extend .title-chapter;
  }
}
</style>
