<template>
  <b-container class="mw-500">
    <div class="promo__bg"></div>
    <b-jumbotron id="register-container">
      <form>
        <div class="login_title">{{ $t("register.title") }}</div>
        <b-form-input
          v-bind:placeholder="$t('register.email')"
          v-model="email"
          class="login-input"
        ></b-form-input>
        <b-form-input
          class="mt-2 login-input"
          v-bind:placeholder="$t('register.name')"
          v-model="name"
          autocomplete="username"
        ></b-form-input>
        <b-form-input
          class="mt-2 login-input"
          type="password"
          v-bind:placeholder="$t('register.password')"
          v-model="password"
          autocomplete="new-password"
        ></b-form-input>
        <b-form-input
          class="mt-2 login-input"
          type="password"
          v-bind:placeholder="$t('register.retype-password')"
          v-model="password2"
          autocomplete="Re-type -password"
        ></b-form-input>
        <b-alert :show="error.state" variant="danger" class="mt-2">
          {{ error.cause }}
        </b-alert>
        <b-button
          block
          class="text-white login-button"
          variant="primary mt-3"
          :disabled="registerInProcess"
          @click="register"
          >
            <span class="text-white" v-if="!registerInProcess">{{ $t("register.register") }}</span>
            <b-spinner v-if="registerInProcess" variant="light"></b-spinner>
          </b-button
        >
        <div class="mt-4 text-center">
          {{ $t("register.have-account") }}
          <router-link class="font-weight-bold" :to="{ name: 'Login' }">{{
            $t("register.login-btn")
          }}</router-link>
        </div>
        <div class="mt-4 text-center fs-075">
          {{ $t("register.forgot-password") }}
          <router-link :to="{ name: 'ResetPassword' }">{{
            $t("register.click-here")
          }}</router-link>
        </div>
      </form>
    </b-jumbotron>
  </b-container>
</template>

<script>
import { KeyCodes } from "../const"
export default {
  data() {
    return {
      email: "",
      password: "",
      name: "",
      password2: "",
      error: {
        state: false,
        cause: ""
      },
      registerInProcess: false
    }
  },
  created() {
    document.title = this.$root.$i18n.messages[this.$root.$i18n.locale].titles["register"]
  },
  mounted() {
    document.getElementById('register-container').addEventListener("keydown", this.onRegisterEnterClick)
  },
  updated() {
    document.title = this.$root.$i18n.messages[this.$root.$i18n.locale].titles["register"]
  },
  methods: {
    register() {
      if (this.password !== this.password2) {
        this.error.state = true
        this.error.cause = "Passwords don't match"
        return
      }
      this.error.state = false
      this.registerInProcess = true
      let _this = this
      this.axios
        .post(
          process.env.VUE_APP_API_BASEURL + "/api/v1/auth/register", { 
            email: this.email, 
            password: this.password, 
            name: this.name 
          }
        )
        .then(function(response) {
          _this.registerInProcess = false
          if (response.status == 200) {
            _this.$router.push({
              name: "Login",
              query: {
                message: "Registration complete. Please check your e-mail."
              }
            })
          }
        })
        .catch(function(error) {
          _this.registerInProcess = false
          _this.error.state = true
          _this.error.cause = error.response && error.response.data && error.response.data.cause
        })
    },
    onRegisterEnterClick(evt) {
      if (evt.keyCode === KeyCodes.ENTER) {
        this.register()
      }
    }
  }
}
</script>

<style>
.login_title {
  font-size: 24px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 20px;
}
.promo__bg {
  position: absolute;
  display: block;
  top: 0;
  right: 0;

  z-index: -1;
  width: 100vw;
  height: 100vh;

  background-image: url("./../assets/Bg-main.png");

  background-repeat: no-repeat;
  background-position: top right;
  @media (max-width: $mobile-width-only) {
    display: none;
  }
}
.login-button {
  font-size: 1.2rem !important;
}
#register-container {
  margin-top: 50px;
  @media (max-width: $mobile-width-only) {
    margin-top: 0px !important;
  }
}
.login-input {
  margin-bottom: 15px !important;
  height: calc(2em + 0.75rem + 2px) !important;
  border: 1px solid #9ea4ab !important;
}
</style>
