<template>
  <main>
    <div class="promo__bg"></div>
    <h1 class="visually-hidden">{{ $t("museum.main-title-charter") }}</h1>
    <div class="in-container museum__main">
      <h2 class="museum__title highlighted">
        {{ $t("museum.title-charter") }}
      </h2>
    </div>
    <section class="museum__section">
      <div class="museum__upper in-container">
        <img
          class="museum__img"
          width="auto"
          height="442px"
          src="./../assets/promo_museums.png"
          v-bind:alt="$t('museum.alt-img.display')"
        />
        <div class="museum__upper-features">
          <h3 class="museum__title-section">{{ $t("museum.title-section") }}</h3>
          <FeaturesList nameOfFeatures="museum" class="museum__features"></FeaturesList>
        </div>
      </div>
      <br><br>
      <h2 class="museum__title highlighted">{{ $t("museum.title-chapter2") }}</h2>

      <div class="museum__lower in-container">
        <div style="width: 100%; aspect-ratio: 1.78; margin: auto;">
          <iframe src="https://www.youtube.com/embed/4Mbko1K5NII" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen class="embed-video"></iframe>
          <!--img width="391px" height="442px" src="./../assets/sample02.jpg" v-bind:alt="$t('development.alt-img.phones')"-->
        </div>
        <div id="archengine-embed-container-museum">
          <iframe src="https://static.ar0.app/archweb.html?uiStyle=dark&allowScale=1&allowMoveCameraSideways=0&showCredit=0&allow-fullscreen=1&annotations-toggle=1&scene=763cacfefd4f07d3" title="ARCHITEQUE 3D" allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true" frameborder="0" style="width: 100%; height: 100%;"></iframe>
        </div>
      </div>
    </section>
    <section>
      <DesctiptionsList
        nameOfDescroptions="museum"
        :listOfDescriptions="descriptions"
      ></DesctiptionsList>
    </section>
    <div class="museum__subtitle in-container">
      <h2 class="museum__title highlighted">
        {{ $t("museum.save-for-future") }}
      </h2>
    </div>
  </main>
</template>

<script>
import FeaturesList from "./FeaturesList.vue";
import DesctiptionsList from "./DesctiptionsList.vue";
export default {
  components: {
    FeaturesList,
    DesctiptionsList
  },
  data: function() {
    return {
      descriptions: ["scale", "features", "history", "education", "media"]
    };
  },
  created() {
    document.title = this.$root.$i18n.messages[this.$root.$i18n.locale].titles[
      "museum"
    ];
  },
  updated() {
    document.title = this.$root.$i18n.messages[this.$root.$i18n.locale].titles[
      "museum"
    ];
  }
};
</script>

<style lang="scss">
.museum__main {
  @media (min-width: $tablet-width) {
    max-width: 800px;
  }
}

.museum__lower_subheader {
  text-align: center;
}

#archengine-embed-container-museum {
  width: 100%;
  margin: auto;
  aspect-ratio: 1.78;

  @media (max-width: $mobile-width-only) {
    margin-top: 30px;
  }
}

#archengine-embed-container-museum iframe {
  background-color: #ededed;
  box-shadow: inset 0px 0px 180px rgba(0, 0, 0, 0.3);
}

.museum__section {

}

.museum__title {
  text-align: center;
  margin-bottom: 10px !important;
  font-size: 36px !important;
  line-height: 44px !important;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: $tablet-width) {
    margin-bottom: 35px !important;
  }

  @extend .font-title;
}

.museum__title2 {
  text-align: center;
  margin-bottom: 10px !important;
  font-size: 28px !important;
  line-height: 44px !important;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: $tablet-width) {
    margin-bottom: 35px !important;
  }

  @extend .font-title;
}

.museum__text {
  @extend .support-text;
  max-width: 800px;
  display: inline-block;
  width: 100%;
}

.museum__text_container {
  text-align: center;
}

.museum__title-section {
  margin-bottom: 20px !important;

  @media (max-width: $mobile-width-only) {
    margin-top: 25px !important;
    margin-bottom: 20px !important;
  }
  
  @extend .title-section;
}

.museum__img {
  width: auto;
  max-width: 100%;
  height: auto;
  padding-top: 50px;
}

.museum_before_img {
  width: auto;
  max-width: 100%;
  height: auto;
  padding-top: 0px;
  margin: auto;
  display: flex;
  margin-bottom: 40px;
}


.museum__upper {
  display: grid;
  padding: 0 10% 10px;
  @media (min-width: $tablet-width) {
    padding: 0;
    grid-gap: 7%;
    grid-template-columns: 47% 44%;
  }
}

.museum__lower_header {
  display: grid;

  @media (min-width: $tablet-width) {
    padding-bottom: 20px;
    grid-gap: 2%;
    grid-template-columns: 49% 49%;
  }
  @media (max-width: $mobile-width-only) {
    display: block;
    margin-bottom: 20px;
  }
}

.museum__lower {
  display: grid;

  @media (min-width: $tablet-width) {
    padding-bottom: 70px;
    grid-gap: 2%;
    grid-template-columns: 49% 49%;
  }
  @media (max-width: $mobile-width-only) {
    display: block;
    margin-bottom: 20px;
  }
}

.museum__upper-features {
  @media (min-width: $tablet-width) {
    padding-top: 30px;
  }
}

.museum__features {
  padding-top: 0px;
}

.descriptions__item--media::before {
  background-image: url("./../assets/media.png");
}

.descriptions__item--features::before {
  background-image: url("./../assets/features.png");
}

.descriptions__item--history::before {
  background-image: url("./../assets/history.png");
}

.descriptions__item--education::before {
  background-image: url("./../assets/education.png");
}

.museum__subtitle {
  padding-top: 50px;
  padding-bottom: 100px;
  text-align: center;

  .museum__title {
    display: inline;

    @extend .font-title;
  }
}
</style>
