import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import en from "./lang/en.json"
import ru from "./lang/ru.json"
import { BootstrapVue, IconsPlugin, ModalPlugin } from "bootstrap-vue"
import axios from "axios"
import VueAxios from "vue-axios"
import VueI18n from "vue-i18n"
import { getLang } from "./utils/getLang"

Vue.use(VueI18n)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueAxios, axios)
Vue.use(ModalPlugin)
Vue.config.productionTip = false

Vue.mixin({
  methods: {
    callTitle() {
      return `CodeChit`;
    },
    getMobileOperatingSystem() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/android/i.test(userAgent)) {
          return "Android";
      }
    
      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          return "iOS";
      }
    
      if ((navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 0) || navigator.platform === 'iPad') {
        return "iOS";
      }
    
      return "unknown";
    }
  },
});

const i18n = new VueI18n({
  locale: getLang(),
  messages: {
    en,
    ru
  }
})


new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount("#app")
