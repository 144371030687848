<template>
  <b-container class="catalog profile-wrapper">
    <div id="not-found-container" hidden=true>
      <img id="not-found-img" src="../assets/not-found.svg" />
      <h2 class="not-found__text">{{$t('404.text')}}</h2>
    </div>
    <div id="profile-wrapper">
      <div class="promo__bg"></div>
      <div id="profile-container">
        <img id="profile-avatar" onerror="this.onerror=null; this.src='/img/avatar_placeholder.svg'" src="./../assets/avatar_placeholder.svg">
        <div id='profile-info-container'>
          <div id="profile-info-name"></div>
          <a id="profile-info-username"></a>
          <div id="profile-info-about"></div>
        </div>
      </div>
      <div id="profile-models-header">{{$t('public-profile.models-list-header')}}</div>
      <div id="profile-models-header-separator"></div>
      <div id="profile-models-spinner-container">
        <b-spinner id="profile-models-spinner" v-if="modelListLoadingInProcess" variant="dark"></b-spinner>
      </div>
      <transition name="model-list-fade">
        <div v-show="showModelList" id="profile-models-list-container">
          <a id="model-item-template" class="model-item-container" hidden=true>
            <img class="model-item-img" onerror="this.onerror=null; this.src='/img/placeholder_16_9.jpg'" src="/img/placeholder_16_9.jpg" />
            <div class="model-item-info-container">
              <div class="model-item-name"></div>
            </div>
          </a>
        </div>
      </transition>
    </div>
  </b-container>
</template>

<script>
export default {
  data: function() {
    return {
      modelListLoadingInProcess: false,
      showModelList: false
    }
  },
  created() {
    document.title = "Architeque"
  },
  updated() {

  },
  methods: {
    
  },
  computed: {
    
  },
  mounted() {
    function replaceAll(str, find, replace) {
      return str.replace(new RegExp(find, 'g'), replace);
    }
    function linkify(inputText) {
      const pattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim;
      let text = inputText.replace(pattern1, '<a href="$1" target="_blank">$1</a>');

      const pattern2 = /(^|[^/])(www\.[\S]+(\b|$))/gim;
      text = text.replace(pattern2, '$1<a href="http://$2" target="_blank">$2</a>');
      return text;
    }
    
    var profileAboutElement = document.getElementById('profile-info-about');
    var profileNameElement = document.getElementById('profile-info-name'); 
    var profileUserNameElement = document.getElementById('profile-info-username'); 
    var profileAvatarElement = document.getElementById('profile-avatar');
    var profileModelsListElement = document.getElementById('profile-models-list-container');
    var profileWrapper = document.getElementById('profile-wrapper');
    var notFoundWrapper = document.getElementById('not-found-container');

    var path = this.$router.currentRoute.path;
    const username = path.substring(path.lastIndexOf('/') + 1)
    console.log("query: " + username);

    let _this = this;
    let headers = this.$root.$refs.Header.getCommonHeaders();

    this.axios.get(process.env.VUE_APP_API_BASEURL + "/api/v1/profile/info", { params: {"username": username}, headers: headers}).then(function(response) {
      //console.log("response: " + JSON.stringify(response) );
      
      // update avatar
      profileUserNameElement.src = process.env.VUE_APP_WEBSITE_URL + "/" + response.data.username;
      profileAvatarElement.src = process.env.VUE_APP_API_BASEURL + "/api/v1/files/downloadAvatar?userID=" + response.data.id;
      profileNameElement.innerHTML = response.data.name;
      profileUserNameElement.innerHTML = "@" + response.data.username;
      profileAboutElement.innerHTML = response.data.about;
      if (response.data.about.length > 0) {
        profileNameElement.style["top-margin"] = "50px";
      }

      document.title = response.data.name + " - Architeque";
    }).catch(function (error) {
      profileWrapper.hidden = true;
      notFoundWrapper.hidden = false;
      console.log("error", error);
    });

    _this.modelListLoadingInProcess = true;

    this.axios.get(process.env.VUE_APP_API_BASEURL + "/api/v1/profile/publicScenes", { params: {"username": username}, headers: headers}).then(function(response) {
      //console.log("response: " + JSON.stringify(response) );
      _this.modelListLoadingInProcess = false;
      _this.showModelList = true;

      var modelItemTemplateElement = document.getElementById('model-item-template');
      if (response.data.length > 0) {
        for (var index in response.data) {
          var model = response.data[index]
  
          var userLang = (navigator.language || navigator.userLanguage).substring(0, 2); 
          var localeId = 1; // en
          if (userLang == "ru") {
            localeId = 2;
          }
  
          var locale = null;
          var defaultLocale = null;
          for (let i = 0; i < model.locales.length; i++) {
            var l = model.locales[i];
            //console.log("Locale [" + i + "] " + JSON.stringify(response.data.locales));
            if (l.cultureId == 1/*en*/) {
              defaultLocale = l;
            }
            if (l.cultureId == localeId) {
              locale = l;
              break;
            }
          }
          if (locale == null) {
            locale = defaultLocale;
          }
  
          var item = modelItemTemplateElement.cloneNode( true );
          item.hidden = false;
          item.style["display"] = "";
          item.id = "";
  
          var img = item.getElementsByClassName("model-item-img")[0];
          var name = item.getElementsByClassName("model-item-name")[0];
          
          img.src = process.env.VUE_APP_API_BASEURL + "/api/v1/files/imageByModelHash?modelHash=" + model.hash + "&fileName=photo_medium.jpeg"
          name.innerHTML = locale.name;
          item.href = process.env.VUE_APP_WEBSITE_URL + "/scene?hash=" + model.hash
  
          profileModelsListElement.appendChild( item );
        }
      } else {
        profileModelsListElement.innerHTML = "No models yet";
        profileModelsListElement.id = "profile-models-list-container-empty";
      }
    }).catch(function (error) {
      _this.showModelList = true;
      _this.modelListLoadingInProcess = false;
      console.log("error", error);
    });
  }
}
</script>

<style lang="scss">
  #profile-models-spinner {
    left: 50%;
    margin-left: -0.75rem;
    position: absolute;
    margin-top: 30px;
  }
  #not-found-container {
    margin-top: 50px !important;
    text-align: center;
    margin: auto;
  }
  #not-found-img {
    width: 100px;
    margin-bottom: 40px;
  }
  #model-item-template {
    display: none;
  }
  #profile-models-list-container-empty {
    text-align: center;
    display: block;
    font-size: 18px;
    line-height: 100px;
    font-weight: 400;
  }
  .model-item-name {
    line-height: 30px;
    font-weight: 500;
    padding-left: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .model-item-info-container {
    height: 30px;
    width: 100%;
  }
  .model-item-container {
    width: 300px;
    height: 200px;
    margin: auto;
  }
  .model-item-img {
    background-color: #FAFAFA;
    width: 300px;
    height: 170px;
    border-radius: 10px;
    box-shadow: 0 16px 24px rgba(0, 0, 0, 0.03), 0 2px 6px rgba(0, 0, 0, 0.1), 0 0 1px rgba(0, 0, 0, 0.05);
  }
  #profile-models-list-container {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fill, 300px);
    grid-gap: 20px;
    justify-content: center;
  }
  .profile-wrapper {
    margin-top: 20px;
    max-width: 1000px !important;
  }
  #profile-info-container {
    margin-left: 30px;
    margin-top: 10px;
    @media (max-width: $mobile-width-only) {
      margin-left: 10px;
      margin-top: 0px;
    }
  }
  #profile-avatar {
    width: 120px;
    height: 120px;
    margin-right: 10px;
    background-color: #f0f0f0;
    border-radius: 60px;
    box-shadow: 0 16px 24px rgba(0, 0, 0, 0.03), 0 2px 6px rgba(0, 0, 0, 0.1), 0 0 1px rgba(0, 0, 0, 0.05);
    @media (max-width: $mobile-width-only) {
      width: 80px;
      height: 80px;
    }
  }
  #profile-models-header {
    font-weight: 500;
    margin-top: 30px;
    font-size: 20px;
    margin-left: 20px;
    @media (max-width: $mobile-width-only) {
      font-size: 16px;
    }
  }

  #profile-models-header-separator {
    height: 1px;
    background-color: #e3e3e3;
    margin-left: 15px;
    margin-right: 15px;
  }
  #profile-container {
    display: flex;
    margin-left: 15px;
  }
  #profile-info-about {
    padding-top: 10px;
    text-align: justify;
    line-height: 1.4;
    font-size: 16px;
    @media (max-width: $mobile-width-only) {
      font-size: 10pt;
    }
  }
  #profile-info-name {
    font-size: 16pt;
    font-weight: 500;
    line-height: 1.3;
    color: black !important;
    @media (max-width: $mobile-width-only) {
      font-size: 12pt;
    }
  }
  #profile-info-username {
    font-size: 18px;
    @media (max-width: $mobile-width-only) {
      font-size: 14px;
    }
  }
  #profile-info-description {
    font-size: 10pt;
    color: white !important;
    @media (max-width: $mobile-width-only) {
      font-size: 8pt;
    }
  }
  .model-list-fade-enter-active, .model-list-fade-leave-active {
    transition: opacity .5s;
  }
  .model-list-fade-enter, .model-list-fade-leave-to {
    opacity: 0;
  }
</style>