<template>
  <header class="in-container header">
    <div class="header__logo">
      <router-link class="logo-link" :to="{ name: 'Home' }" v-if="$route.path !== '/'">
        <img
          class="logo"
          src="./../assets/architeque_logo.svg"
          v-bind:alt="$t('alt-logo')"
        />
      </router-link>
      <img
        v-else
        class="logo"
        src="./../assets/architeque_logo.svg"
        v-bind:alt="$t('alt-logo')"
      />
    </div>
    <nav class="header__nav header-nav">
      <button
        ref="toggle"
        class="header__toggle header-toggle"
        v-on:click="onToggleClick"
      >
        <div></div>
      </button>
      <ul ref="list" class="header-nav__list header-nav-list">

        <li class="header-nav__item">
          <router-link
            class="header-nav-list__link"
            :to="{ name: 'Business' }"
            >{{ $t("header.navigation.business") }}</router-link
          >
        </li>

        <li class="header-nav__item">
          <router-link
            class="header-nav-list__link"
            :to="{ name: 'Museums' }"
            >{{ $t("header.navigation.museums") }}</router-link
          >
        </li>

        <li class="header-nav__item">
          <router-link
            class="header-nav-list__link"
            :to="{ name: 'Education' }"
            >{{ $t("header.navigation.education") }}</router-link
          >
        </li>

        <li class="header-nav__item">
          <router-link
            class="header-nav-list__link"
            :to="{ name: 'Toolkit' }"
            >{{ $t("header.navigation.toolkit") }}</router-link
          >
        </li>

        <li class="user-block">
          <b-link
            :to="{ name: 'Login' }"
            class="sign-in-link link"
            v-if="this.$store.state.isLoggedIn === false"
            >{{ $t("header.user-block.unlogged") }}</b-link
          >
          <b-link
            :to="{ name: 'Profile' }"
            class="sign-in-link link"
            v-if="this.$store.state.isLoggedIn === true"
            >{{ $t("header.user-block.logged") }}</b-link
          >
          <!--div
            class="languages"
            v-bind:class="{ languagesOpen: isLangSelectVisible }"
            v-on:mouseleave="onLangSelectOut"
          >
            <button
              class="languages-select-item__btn"
              v-on:click="onLangBtnClick"
              v-bind:value="this.$root.$i18n.locale"
            >
              {{ languages[this.$root.$i18n.locale] }}
            </button>
            <ul class="languages-select" v-if="isLangSelectVisible">
              <li
                v-for="language in availableNotActiveLanguages"
                v-bind:key="language"
                class="languages-select__item languages-select-item"
              >
                <button
                  class="languages-select-item__btn"
                  v-on:click="onLangChangeBtn"
                  v-bind:value="language"
                >
                  {{ languages[language] }}
                </button>
              </li>
            </ul>
          </div-->
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
import { Languages } from "./../const";
export default {
  metaInfo: {
    meta: [
      { name: 'og:site_name', content: "Architeque" },
      { name: 'og:type', content: "website" },
      { name: 'og:description', content: "3D and AR solutions" }
    ]
  },
  data: function() {
    return {
      languages: Languages,
      isLangSelectVisible: false
    };
  },
  computed: {
    availableNotActiveLanguages: function() {
      return this.$root.$i18n.availableLocales.filter(
        language => language !== this.$root.$i18n.locale
      );
    }
  },
  methods: {
    onLangSelectOut: function() {
      this.isLangSelectVisible = false;
    },
    onLangBtnClick: function() {
      this.isLangSelectVisible = true;
    },
    onLangChangeBtn: function({ target }) {
      if (this.$root.$i18n.locale !== target.value) {
        this.$root.$i18n.locale = target.value;
        document.documentElement.setAttribute("lang", this.$root.$i18n.locale);
      }
      this.isLangSelectVisible = false;
    },
    onToggleClick: function() {
      this.$refs.list.classList.toggle("header-nav__list--visible");
      this.$refs.toggle.classList.toggle("header-toggle--open");
    },
    getCommonHeaders: function() {
      return this.$store.state.token ? {
        "Authorization": "Bearer " + this.$store.state.token
      } : {};
    },
    updateTags: function() {
      // site_name
      this.meta1 = this.getMeta("og:site_name");
      if (this.meta1 == null) {
        let meta1 = document.createElement("meta");
        meta1.setAttribute("property", "og:site_name");
        document.head.appendChild(meta1);
        this.meta1 = meta1;
      }
      
      this.meta1.setAttribute("content", this.tagSiteName || "Architeque");

      // title
      this.meta2 = this.getMeta("og:title");
      if (this.meta2 == null) {
        console.log("append meta2");
        let meta2 = document.createElement("meta");
        meta2.setAttribute("property", "og:title");
        document.head.appendChild(meta2);
        this.meta2 = meta2;
      }

      this.meta2.setAttribute("content", this.tagTitle || this.$root.$i18n.messages[this.$root.$i18n.locale].titles["title"] );
  
      // type
      this.meta3 = this.getMeta("og:type");
      if (this.meta3 == null) {
        let meta3 = document.createElement("meta");
        meta3.setAttribute("property", "og:type");
        meta3.setAttribute("content", "website");
        document.head.appendChild(meta3);
        this.meta3 = meta3;
      }
      
  
      // description
      this.meta4 = this.getMeta("og:description");
      if (this.meta4 == null) {
        let meta4 = document.createElement("meta");
        meta4.setAttribute("property", "og:description");
        document.head.appendChild(meta4);
        this.meta4 = meta4;
      }
      this.meta4.setAttribute("content", this.tagDescription || this.$root.$i18n.messages[this.$root.$i18n.locale].titles['description'] );
      
  
      // image
      this.meta5 = this.getMeta("og:image");
      if (this.meta5 == null) {
        let meta5 = document.createElement("meta");
        meta5.setAttribute("property", "og:image");
        meta5.setAttribute("itemprop", "image primaryImageOfPage");
        document.head.appendChild(meta5);
        this.meta5 = meta5;
      }
      this.meta5.setAttribute("content", this.tagImage || "/favicon.png");
      
      // url
      this.meta6 = this.getMeta("og:url");
      if (this.meta6 == null) {
        let meta6 = document.createElement("meta");
        meta6.setAttribute("property", "og:url");
        meta6.setAttribute("content", "https://ar-chiteque.com");
        document.head.appendChild(meta6);
        this.meta6 = meta6;
      }
    },
    getMeta: function (metaProperty) {
      const metas = document.getElementsByTagName('meta');

      for (let i = 0; i < metas.length; i++) {
        if (metas[i].getAttribute('property') === metaProperty) {
          return metas[i];
        }
      }

      return null;
    }
  },
  watch: {
    $route() {
      this.$refs.list.classList.remove("header-nav__list--visible");
      this.$refs.toggle.classList.remove("header-toggle--open");
    }
  },
  mounted() {
    this.$root.$refs.Header = this;

    function setHeight() {
      var vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }
    

    setHeight();
    window.addEventListener("resize", setHeight);
    let analyticsScript1 = document.createElement("script");
    analyticsScript1.setAttribute("async", "");
    analyticsScript1.setAttribute(
      "src",
      "https://www.googletagmanager.com/gtag/js?id=G-GKLD7DZ7SS"
    );
    document.head.appendChild(analyticsScript1);

    let analyticsScript2 = document.createElement("script");
    analyticsScript2.setAttribute("src", "analytics.js");
    document.head.appendChild(analyticsScript2);

    // Redirect to https in production
    if (!(process.env.NODE_ENV === 'development')) {
      if (window.location.protocol != "https:") {
        location.href = location.href.replace("http://", "https://");
        return;
      }
    }

    this.updateTags();

    //let preload1 = document.createElement("link");
    //preload1.setAttribute("rel", "preload");
    //preload1.setAttribute("href", "https://dev.ar0.app/archweb.data");
    //preload1.setAttribute("as", "fetch");
    //preload1.setAttribute("crossorigin", "");
    //preload1.setAttribute("type", "application/data");
    //document.head.appendChild(preload1);
//
    //let preload2 = document.createElement("link");
    //preload2.setAttribute("rel", "preload");
    //preload2.setAttribute("href", "https://dev.ar0.app/archweb.wasm");
    //preload2.setAttribute("as", "fetch");
    //preload2.setAttribute("crossorigin", "");
    //preload2.setAttribute("type", "application/wasm");
    //document.head.appendChild(preload2);
  }
};
</script>

<style lang="scss">

.logo-link {
  box-shadow: none !important;
}
.logo {
  width: 180px;
  height: 36px;
}

.header {
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  z-index: 1;

  @media (min-width: $width-no-burger-menu) {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 50px;

    font-size: 16px;
    line-height: 17px;
  }
  @media (max-width: $mobile-width-only) {
    padding-bottom: 10px !important;
  }
}

.header__logo {
  padding-bottom: 20px;
  flex-shrink: 0;
  margin-right: 15px;
  @media (min-width: $width-no-burger-menu) {
    padding-bottom: 0;
  }
}

.header-nav {
  position: relative;
}

.sign-in-link {
  display: block;
  width: 100%;
  min-width: 150px;
  height: 41px;
  background-color: #5ab5ba !important;

  line-height: 39px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  
  box-shadow: 0 16px 24px rgba(90, 181, 186, 0.2), 0 2px 6px rgba(90, 181, 186, 0.12), 0 0 1px rgba(0, 0, 0, 0.04);

  color: white !important;

  @extend .font-link-btn-2;

  @media (min-width: $width-no-burger-menu) {
    width: auto;
    height: 39px;

    font-weight: 500;

    border-radius: 5px;
  }
}

.header__toggle {
  position: absolute;
  top: -35px;
  right: -10px;

  width: 30px;
  padding: 0;
  display: block;

  background-color: transparent;
  border: none;
  transform: translate(-50%, -50%);
  @media (min-width: $width-no-burger-menu) {
    display: none;
  }
}

.header__toggle::before,
.header__toggle::after,
.header__toggle div {
  content: "";

  display: block;
  width: 100%;
  height: 4px;
  margin: 4px 0;

  background: $secondary-dark;
  border-radius: 3px;

  transition: 0.5s;
}

.header-toggle--open:before {
  transform: translateY(9px) rotate(135deg);
}

.header-toggle--open:after {
  transform: translateY(-7px) rotate(-135deg);
}

.header-toggle--open div {
  transform: scale(0);
}

.header-nav__list {
  display: none;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0 !important;
  padding-left: 0;

  list-style: none;

  @media (min-width: $width-no-burger-menu) {
    position: relative;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.header-nav__list--visible {
  display: flex;

  border-top: 1px $light-gray solid;
}

.nav-list-enter,
.nav-list-leave-to {
  transform: translateX(10px);
  opacity: 0;
}

.nav-list-enter-active,
.nav-list-leave-active {
  transition: all 1s;
}

.header-nav-list__link {
  display: block;
  width: 100%;
  padding: 12px 25px;

  color: $secondary-dark !important;

  border-bottom: 1px $light-gray solid;

  @media (min-width: $width-no-burger-menu) {
    border-bottom: none;
    border-radius: 5px;
  }
}

.header-nav-list__link:hover,
.header-nav-list__link:focus {
  background-color: $secondary !important;
  text-decoration: none;
}

.header-nav__item {
  width: 100%;

  text-align: center;

  @extend .font-link-btn-2;

  @media (min-width: $width-no-burger-menu) {
    width: auto;
  }
}

.user-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (min-width: $width-no-burger-menu) {
    width: auto;
    margin-left: 15px;
  }
}

.languages {
  width: 100%;
  min-width: 100px;

  @media (min-width: $width-no-burger-menu) {
    position: absolute;
    top: 0;
    right: 0;

    width: auto;
    overflow: hidden;

    border: 1px solid transparent;
    border-radius: 5px;
  }
}

.languagesOpen {
  @media (min-width: $width-no-burger-menu) {
    border-color: $light-gray;
  }
}

.languages-select-item__btn {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-top: 11px;
  padding-bottom: 11px;

  text-transform: uppercase !important;

  background-color: transparent;
  border: none;
  border-bottom: 1px $light-gray solid;

  @extend .font-link-btn-2;

  @media (min-width: $width-no-burger-menu) {
    padding-top: 10px;
    padding-bottom: 10px;

    border-bottom: none;
  }
}

.languages-select-item__btn:hover,
.languages-select-item__btn:focus {
  background-color: $secondary;
}

.languages-select-item__btn:focus {
  outline: none !important;
  opacity: 0.8;
}

.languages-select-item__btn:active {
  opacity: 1;
}

.languages-select {
  margin: 0;
  padding: 0;

  list-style: none;
}
.embed-video {
  width: 100%; 
  height: 100%;
  @media (max-width: $mobile-width-only) {
    min-height: 320px;
  }
}
</style>
