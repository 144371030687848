<template>
  <section class="in-container in-container-add promo-section">
    <div class="promo__bg"></div>
    <div style="position: relative;">
      <div class="promo promo_wrapper">
        <h2 class="promo__title" v-html="$t('promo.title')"></h2>
        <ul class="promo__list promo-list">
          <li class="promo-list__item">{{$t('promo.list.1')}}</li>
          <li class="promo-list__item" v-html="$t('promo.list.2')"></li>
          <li class="promo-list__item">{{$t('promo.list.3')}}</li>
          <li class="promo-list__item">{{$t('promo.list.4')}}</li>
          <li class="promo-list__item">{{$t('promo.list.5')}}</li>
          <br>
          <li class=""> 
            <div class="promo__btn-container">
              <b-link :to="{name: 'Toolkit'}" class="button_promo btn" id="promo-btn-try" hidden>{{$t('btn-try-for-free')}}</b-link>
              <a id="promo-btn-ios" class="promo__btn--ios promo__btn" href="https://apps.apple.com/app/id1447699048" hidden>{{$t('link-to-app-store')}}</a>
              <a id="promo-btn-android" class="promo__btn--android promo__btn" hidden href="https://play.google.com/store/apps/details?id=com.architeque.android.app">{{$t('link-to-google-play')}}</a>
            </div>
          </li>
        </ul>
        <div id="archengine-embed-container-promo"> 
          <iframe id="archengine-frame" src="https://static.ar0.app/archweb.html?transparent=1&uiStyle=dark&allowScale=0&allowMoveCameraSideways=0&showCredit=0&allow-fullscreen=1&annotations-toggle=0&annotations-visible=1&camera-direction=0.8,-0.1,-0.5&vignette=1&background-color=ffffff&scene=658fd3660c0083a6" allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true" title="ARCHITEQUE 3D" frameborder="0" style="width: 100%; height: 100%;"></iframe>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Promo',
  mounted() {
    // Pass engine parameters to the engine
    var archengineFrame = document.getElementById('archengine-frame');
    var search = window.location.search;
    if (typeof search === 'string' || search instanceof String) {
      search = search.replaceAll("?", "")
      var hashElements = search.split(",")
      var hash = hashElements.length > 0 ? hashElements[0] : ""
      if (hash.length > 0) {
        archengineFrame.src = archengineFrame.src + "&scene=" + hash
      }
    }

    // Show button depending on context
    var buttoniOS     = document.getElementById('promo-btn-ios');
    var buttonAndroid = document.getElementById('promo-btn-android');
    var buttonTry = document.getElementById('promo-btn-try');

    var os = this.getMobileOperatingSystem();
    if (os == "iOS") {
      buttoniOS.hidden = false;
    } else if (os == "Android") {
      buttonAndroid.hidden = false
    } else {
      buttonTry.hidden = false;
    }
  }
};
</script>;

<style lang="scss">

.button_promo {
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 22px !important;
  color: $white !important;
  width: 250px;
  background-color: $bg-block !important;
  border-radius: 7px !important;
  transition: 0.15s ease-in-out;

  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 19px 0px 20px 0px !important;

  box-shadow: 0 16px 24px rgba(90, 181, 186, 0.2), 0 2px 6px rgba(90, 181, 186, 0.12), 0 0 1px rgba(0, 0, 0, 0.04);

  @extend .font-link-btn-1;

  @media (min-width: $tablet-width) {
    min-width: 258px;
  }
  @media (max-width: $mobile-width-only) {
    width: 100%;
  }
}
.button_promo::after {
  content: "";

  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: -30px;
  margin-left: 10px;

  vertical-align: middle;

  background-image: url("~@/assets/icon-right-arrow.svg");
}
.button_promo:hover,
.button_promo:focus {
  background-color: $color-bg-hover !important;
}

.button_promo:active {
  background-color: $color-bg-active !important;
}
.promo-section {
  
}
.in-container-add {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.promo_wrapper {
  padding-left: 20px !important;
  padding-right: 20px !important;
  pointer-events: none;
}
#archengine-embed-container-promo {
  position: absolute; 
  width: 850px; 
  height: 100%;
  z-index: 6;
  left: 250px;
  pointer-events: all !important;
  @media (max-width: $mobile-width-only) {
    aspect-ratio: 1.3 !important;
    width: 100% !important;
    position: relative !important;
    left: 0;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    box-shadow: inset 0px 0px 80px rgb(150, 150, 150);
  }
}
.promo {
  display: flex !important;
  flex-direction: column;
  padding-bottom: 80px;
  position: relative;
  @media (max-width: $mobile-width-only) {
    padding-bottom: 20px;
  }
}

.promo__bg {
  position: absolute;
  display: block;
  top: 0;
  right: 0;

  z-index: -1;
  width: 100vw;
  height: 100vh;

  background-image: url("./../assets/Bg-main.png");

  background-repeat: no-repeat;
  background-position: top right;
  @media (max-width: $mobile-width-only) {

  }
}

.promo__title {
  max-width: 600px;
  margin-top: 20px !important;
  margin-bottom: 50px !important;
  padding-right: 40px;

  letter-spacing: -1px;
  
  font-size: 30px !important;
  line-height: 1.3 !important;

  z-index: 5;

  @extend .font-title;

  @media (min-width: $tablet-width) {
    min-width: 728px;
    max-width: none;
    padding-right: 120px;

    font-size: 64px !important;
  }
  @media (max-width: $mobile-width-only) {
    margin-bottom: 20px !important;
    font-size: 8vmin !important;
    margin-top: 0px !important;
  }
}

.promo__btn-container {
  max-width: 500px;
  pointer-events: all !important;
  z-index: 7;
  @media (max-width: $mobile-width-only) {
    width: 100%;
  }
}

.promo__btn {
  position: relative;

  display: inline-block;
  width: 205px;
  min-height: 50px;
  padding-top: 14px;
  padding-right: 10px;
  padding-bottom: 15px;
  padding-left: 45px;

  font-size: 12px;
  line-height: 1.5;
  text-align: left;

  border: 2px solid $bg-dark;
  border-radius: 8px;
  cursor: pointer;

  @extend .font-link-btn-1;

  @media (min-width: $tablet-width) {
    display: inline-block;
    width: 221px;
    min-height: 67px;
    padding-top: 17px;
    padding-right: 19px;
    padding-bottom: 16px;

    font-size: 18px;
  }
}

.promo__btn::before {
  content: "";
  position: absolute;
  top: 12px;
  left: 15px;

  display: inline-block;
  width: 20px;
  height: 20px;

  @media (min-width: $tablet-width) {
    top: 16px;
    left: 19px;

    width: 28px;
    height: 28px;
  }
}

.promo__btn--ios {
  color: $white !important;

  background-color: $bg-dark !important;

  @media (min-width: $tablet-width) {
    padding-left: 70px;
  }
}

.promo__btn--android {
  color: $white !important;
  background-color: $bg-dark !important;
  @media (min-width: $tablet-width) {
    padding-left: 65px;
  }
}

.promo__btn--ios::before {
  background-image: url("./../assets/icon-app-store.svg");
  background-repeat: no-repeat;
  background-size: 20px 20px;
  @media (min-width: $tablet-width) {
    background-size: 28px 28px;
  }
}

.promo__btn--android::before {
  background-image: url("./../assets/icon-playstore.svg");
  background-repeat: no-repeat;
  background-size: 20px 20px;
  @media (min-width: $tablet-width) {
    background-size: 28px 28px;
  }
}

.promo-list {
  margin-bottom: 20 !important;
  padding: 0;

  list-style-type: none;
  z-index: 5;
}

.promo-list__item {
  display: block;

  font-size: 14px;
  line-height: 1.2;

  @extend .font-title;

  @media (min-width: $tablet-width) {
    font-size: 18px;
  }
}

.promo-list__item:not(:last-child) {
  margin-bottom: 10px;
  @media (min-width: $tablet-width) {
    margin-bottom: 20px;
  }
}

.promo-list__item::before {
  content: "";

  display: inline-block;
  width: 5px;
  height: 5px;
  margin-right: 8px;

  line-height: 18px;
  vertical-align: middle;
  background-color: $secondary-dark;
  border-radius: 50%;
}

.promo__img {
  display: none;
  @media (min-width: $tablet-width) {
    position: absolute;
    right: 0;
    bottom: -130px;

    display: inline-block;
  }
}

</style>
