<template>
  <HowToEn v-if="activeLang === 'en'" :key="'en'" />
  <HowToRu v-else :key="'ru'" />
</template>

<script>
import HowToRu from "../views/HowTo-ru.vue"
import HowToEn from "../views/HowTo-en.vue"
export default {
  components: {
    HowToRu,
    HowToEn
  },
  computed: {
    activeLang: function() {
      return this.$root.$i18n.locale
    }
  }
}
</script>
