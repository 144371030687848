<template>
  <div class="in-container invitation">
    <div class="invitation__title-container">
      <h2 class="invitation__title highlighted">{{$t('invitation.title')}}</h2>
    </div>
    <div class="invitation__btn-container">
      <a id="footer-btn-ios" href="https://apps.apple.com/app/id1447699048" class="footer__btn--ios"><img src="./../assets/AppStore.svg"></a>
      <a id="footer-btn-android" href="https://play.google.com/store/apps/details?id=com.architeque.android.app" class="footer__btn--android"><img src="./../assets/GooglePlay.png"></a>
    </div>
  </div>
</template>

<style lang="scss">
.invitation {
  padding-top: 140px ;
  padding-bottom: 140px;

  text-align: center;
}

.invitation__title-container {
  @extend .title-chapter;
}

.invitation__title {
  display: inline;

  @extend .title-chapter;
}

.invitation__btn-container {
  justify-content: center;
}

.footer__btn--ios {
  height: 60px;
  display: inline-flex;
  margin: 10px;
}

.footer__btn--ios img {
  max-width: none !important;
}

.footer__btn--android {
  height: 60px;
  display: inline-flex;
  margin: 10px;
}

.footer__btn--android img {
  max-width: none !important;
}

</style>

<script>
export default {
  name: 'Promo',
  mounted() {
    // Show button depending on context
    var buttoniOS     = document.getElementById('footer-btn-ios');
    var buttonAndroid = document.getElementById('footer-btn-android');
    var os = this.getMobileOperatingSystem();
    
    if (os == "iOS") {
      buttoniOS.hidden = false;
    } else if (os == "Android") {
      buttonAndroid.hidden = false
    } else {
      buttoniOS.hidden = false;
      buttonAndroid.hidden = false;
    }
  }
};
</script>;