<template>
  <b-container class="catalog">
    <div class="catalog__bg"></div>
    <div>
      <div id="scene-embed-container">
        <div id="scene-embed-info">
          <div id="scene-embed-name-container">
            <div id="scene-embed-user-container">
              <a id="scene-embed-avatar-link">
                <img id="scene-embed-user-avatar" onerror="this.onerror=null; this.src='favicon.png'" src="./../assets/avatar_placeholder.svg">
              </a>
              <div id='scene-embed-info-container'>
                <div id="scene-embed-name"></div>
                <a id="scene-embed-user-name"></a>
              </div>
            </div>
          </div>
        </div>
        <iframe id="scene-embed-container-iframe" title="ARCHITEQUE 3D" allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true" frameborder="0" style="margin: 0px; height: 100%; width: 100%;"></iframe>
      </div>
    </div>
      <div id="scene-embed-menu-panel">
        <div id="scene-embed-stat-container">
          <img id="scene-embed-downloads-stat-icon" alt="Downloads" src="../assets/download_stat.svg" />
          <div id="scene-embed-downloads-stat-label"></div>
        </div>
      </div>
      <div id="scene-embed-description">
    </div>
    <a id='to-app-button' v-on:click="goToApp">Show in App</a>
  </b-container>
</template>

<script>
export default {
  created() {
    document.title = this.$root.$i18n.messages[this.$root.$i18n.locale].titles["3d-preview-title"];
  },
  updated() {

  },
  methods: {
    getMobileOperatingSystem: function() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/android/i.test(userAgent)) {
          return "Android";
      }
  
      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          return "iOS";
      }
  
      if ((navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 0) || navigator.platform === 'iPad') {
        return "iOS";
      }

      return "unknown";
    },
    goToApp: function() {
      let _this = this;
      setTimeout(function() {
        let os = _this.getMobileOperatingSystem();
        if (os == "Android") {
            window.location.href = "https://play.google.com/store/apps/details?id=com.architeque.android.app"; 
        } else if (os == "iOS") {
            window.location.href = "https://itunes.apple.com/app/apple-store/id1447699048";
        } else {
            window.location.href = "https://www.ar-chiteque.com";
        }
      }, 200);
      
      window.location = "architeque://ar0.app/c/" + this.$route.query.hash;
    }
  },
  computed: {
    
  },
  mounted() {
    function replaceAll(str, find, replace) {
      return str.replace(new RegExp(find, 'g'), replace);
    }
    function linkify(inputText) {
      const pattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim;
      let text = inputText.replace(pattern1, '<a href="$1" target="_blank">$1</a>');

      const pattern2 = /(^|[^/])(www\.[\S]+(\b|$))/gim;
      text = text.replace(pattern2, '$1<a href="http://$2" target="_blank">$2</a>');
      return text;
    }
    
    //console.log("Current OS: " + this.getMobileOperatingSystem());

    var containerFrame = document.getElementById('scene-embed-container-iframe');
    var sceneNameElement = document.getElementById('scene-embed-name');
    var sceneUserName = document.getElementById('scene-embed-user-name'); 
    var sceneEmbedDescription = document.getElementById('scene-embed-description'); 
    var toAppButtonElement = document.getElementById('to-app-button');
    var sceneUserAvatar = document.getElementById('scene-embed-user-avatar');
    var downloadsLabelElement = document.getElementById('scene-embed-downloads-stat-label');
    var avatarLinkElement = document.getElementById('scene-embed-avatar-link');

    toAppButtonElement.style["display"] = "none";

    // Construct embedded scene url for iframe
    var webURL = process.env.VUE_APP_STATIC_URL + "/archweb.html?&allowScale=1&uiStyle=dark&allowMoveCameraSideways=0&showCredit=0&allow-fullscreen=1&annotations-toggle=1&background-color=ffffff&show-engine-version=1&scene=" + this.$route.query.hash + "&token=" + this.$store.state.token

    // Pass custom server api url in development mode for testing/debugging
    if (process.env.NODE_ENV === 'development') {
      webURL += "&api=" + process.env.VUE_APP_API_BASEURL;
    }
    containerFrame.src = webURL;

    var sceneHash = this.$route.query.hash;
    let _this = this;
    let headers = this.$root.$refs.Header.getCommonHeaders();
    this.axios.get(process.env.VUE_APP_API_BASEURL + "/api/v1/models/hash", { params: {"hash": sceneHash}, headers: headers}).then(function(response) {
      //console.log("response: " + JSON.stringify(response) );
      //console.log("response: " + response.data.locales[0].description );
      var userLang = (navigator.language || navigator.userLanguage).substring(0, 2); 
      var localeId = 1; // en
      if (userLang == "ru") {
        localeId = 2;
      }
      var locale = null;
      var defaultLocale = null;
      for (let i = 0; i < response.data.locales.length; i++) {
        var l = response.data.locales[i];
        //console.log("Locale [" + i + "] " + JSON.stringify(response.data.locales));
        if (l.cultureId == 1/*en*/) {
          defaultLocale = l;
        }
        if (l.cultureId == localeId) {
          locale = l;
          break;
        }
      }
      if (locale == null) {
        locale = defaultLocale;
      }
      var sceneName = locale.name;

      document.title = sceneName + " - " + response.data.user.name;

      sceneUserName.innerHTML = response.data.user.username + "</b>"
      const userLink = process.env.VUE_APP_WEBSITE_URL + "/" + response.data.user.username
      sceneUserName.href = userLink
      avatarLinkElement.href = userLink
      sceneNameElement.innerHTML = sceneName;

      var description = locale.description;
      description = replaceAll(description, "\n", "<br/>");
      description = linkify(description);
      sceneEmbedDescription.innerHTML = description;
      
      downloadsLabelElement.innerHTML = response.data.downloadsCount;

      // update avatar
      sceneUserAvatar.src = process.env.VUE_APP_API_BASEURL + "/api/v1/files/downloadAvatar?userID=" + response.data.userId;

      // Update tags
      _this.$root.$refs.Header.tagTitle = sceneName;
      _this.$root.$refs.Header.tagImage = process.env.VUE_APP_API_BASEURL + "/api/v1/files/imageByModelHash?modelHash=" + sceneHash + "&fileName=photo_square.jpeg&rand=" + Math.random().toString();
      _this.$root.$refs.Header.updateTags();

      let os = _this.getMobileOperatingSystem();
      if (os == "Android" || os == "iOS") {
        toAppButtonElement.style["display"] = "block";
      }
    }).catch(function (error) {
      console.log("error", error);
    });
  }
}
</script>

<style lang="scss">
  #scene-embed-downloads-stat-label {
    font-weight: 500;
    padding-top: 1px;
  }
  #scene-embed-downloads-stat-icon {
    height: 40px;
    padding: 10px 5px 10px 10px;
  }
  #scene-embed-stat-container {
    line-height: 40px;
    display: flex;
  }
  #scene-embed-menu-panel {
    height: 40px;
    margin-bottom: 10px;
    border-bottom: 1px solid #f0f0f0;
  }
  #scene-embed-info-container {
    margin-top: 3px;
  }
  #scene-embed-name-container {
    padding: 15px 0px 15px 20px;
    @media (max-width: $mobile-width-only) {
      padding: 10px 0px 10px 15px;
    }
  } 
  #scene-embed-user-avatar {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 20px;
  }
  #scene-embed-user-container {
    display: flex;
  }
  #scene-embed-description {
    padding: 10px;
    text-align: justify;
    line-height: 1.4;
  }
  #scene-embed-name {
    font-size: 12pt;
    line-height: 1.0;
    color: white !important;
    text-shadow: 0 0 5px var(--color-black-a80,rgba(0,0,0,.8));
    @media (max-width: $mobile-width-only) {
      font-size: 10pt;
    }
  }
  #scene-embed-user-name {
    font-size: 10pt;
    color: white !important;
    text-shadow: 0 0 5px var(--color-black-a80,rgba(0,0,0,.8));
    @media (max-width: $mobile-width-only) {
      font-size: 8pt;
    }
  }
  #scene-embed-info {
    display: flex;
    position: absolute;
  }
  #scene-embed-container {
    width: 100%;
    aspect-ratio: 1.7;
    @media (max-width: $mobile-width-only) {
      aspect-ratio: 1.3;
    }
  }
  #scene-embed-container iframe {
    background-color: #ededed;
  }
  #to-app-button {
    display: block;
    margin: 2vmin auto auto auto;
    padding-top: 3vmin;
    padding-bottom: 3vmin;

    color: #ffffff !important;
    text-decoration: none !important;

    background: #5ab5ba !important;
    border-radius: 1.5vmin;
    cursor: pointer;

    user-select: none;
    text-align: center;
    max-width: 380px;

    @media (min-width: $tablet-width) {
      font: 2.5vmin;
      height: 60px;
      max-width: 320px;
      padding-top: 2vmin;
    }
  }
  #to-app-button:after {
    content: "";

    display: inline-block;
    width: 4vmin;
    height: 4vmin;
    margin-right: -4vmin;
    margin-left: 2vmin;

    vertical-align: middle;

    background-image: url(https://www.ar-chiteque.com/icon-right-arrow.svg);
    @media (min-width: $tablet-width) {
      width: 3vmin;
      height: 3vmin;
    }
  }
  #to-app-button:active {
    -webkit-transform: translateY(2px);
        -ms-transform: translateY(2px);
            transform: translateY(2px);
  }
  #scene-embed-container-iframe {
    border-radius: 3px;
  }
</style>